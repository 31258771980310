"use client"; // Error components must be Client Components

import "./not-found.css";

import { GetPathLanguage, useTranslation } from "@/i18n/client";
import Link from "next/link";
import React from "react";
import { Trans } from "react-i18next/TransWithoutContext";

export default function NotFound(): React.JSX.Element {
  const language = GetPathLanguage();
  const lang = language || "en";

  const { t } = useTranslation(lang, "not-found");

  const code: string = "404";

  return (
    <div className="not-found-container">
      <h1>
        <Trans i18nKey="title" t={t}>
          {{ code }}
        </Trans>
      </h1>
      <h2>
        <Trans i18nKey={"subtitle"} t={t} />
      </h2>
      <p>
        <Trans i18nKey={"description1"} t={t} />
      </p>
      <p>
        <Trans i18nKey={"description2"} t={t} />
      </p>
      <Trans i18nKey={"home"} t={t}
       components={{1: <Link replace href="/"/>}} />
    </div>
  );
}
